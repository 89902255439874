import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import FormData from "form-data"
import {
  FaPhoneAlt,
  FaRegUser,
  FaRegEnvelope,
  FaBriefcase,
} from "react-icons/fa"

export default class Contact extends Component {
  // state
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      contact: "",
      business: "",
      message: "",
      isError: {
        name: "",
        email: "",
        contact: "",
      },
      submitMessage: "",
      isSubmitted: false,
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    let submitMessage = this.state.submitMessage
    let isFormValid = true
    let isSubmitted
    if (
      this.state.name.length < 1 ||
      this.state.email.length < 1 ||
      this.state.contact.length < 1
    )
      isFormValid = false
    if (
      this.state.isError.name.length > 0 ||
      this.state.isError.email.length > 0 ||
      this.state.isError.contact.length > 0
    )
      isFormValid = false

    if (isFormValid) {
      submitMessage =
        "Thank you for getting in touch! One of our colleagues will get back in touch with you soon!Have a great day!"
      isSubmitted = true
      this.submitForm()
    } else {
      submitMessage =
        "One or more fields have an error. Please check and try again."
      this.updateErrorMessage({
        name: this.state.name,
        email: this.state.email,
        contact: this.state.contact,
      })
    }
    this.setState({ submitMessage, isSubmitted })
  }

  submitForm = () => {
    const contactData = new FormData()
    contactData.append("contact-name", this.state.name)
    contactData.append("email", this.state.email)
    contactData.append("contact-number", this.state.contact)
    contactData.append("business-name", this.state.business)
    contactData.append("message", this.state.message)
    let config = {
      method: "post",
      url:
        "http://fortis.wordofmouthagency.com.au/wp-json/contact-form-7/v1/contact-forms/11/feedback",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: contactData,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
      })
      .catch(function (error) {
        console.log(error)
      })
    this.clearForm()
  }
  updateErrorMessage = items => {
    let isError = { ...this.state.isError }

    for (const [name, value] of Object.entries(items)) {
      switch (name) {
        case "name":
          isError.name = value.length <= 0 ? "Field cannot be empty" : ""
          break
        case "email":
          isError.email = regExpEmail.test(value)
            ? ""
            : "Email address is invalid"
          break
        case "contact":
          isError.contact = regExpNumber.test(value)
            ? ""
            : "Contact number is invalid"
          break
        default:
          break
      }
    }
    this.setState({ isError })
  }

  handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    var obj = {}
    obj[name] = value
    this.updateErrorMessage(obj)
    this.setState({
      [name]: value,
    })
  }

  clearForm = () => {
    const newState = {
      name: "",
      email: "",
      contact: "",
      business: "",
      message: "",
      isError: {
        name: "",
        email: "",
        contact: "",
      },
      submitMessage: "",
      isSubmitted: false,
    }
    this.setState({ ...newState })
  }
  render() {
    const { isError } = this.state
    return (
      <Layout>
        <SEO title="Contact" />
        <section className="contact">
          <div className="container">
            <h1>Get in Touch</h1>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder="Name"
                  />
                  <FaRegUser />
                  {isError.name.length > 0 && (
                    <span className="error">{isError.name}</span>
                  )}
                </div>
                <div className="col-6">
                  <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                  />
                  <FaRegEnvelope />
                  {isError.email.length > 0 && (
                    <span className="error">{isError.email}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    type="tel"
                    name="contact"
                    value={this.state.contact}
                    onChange={this.handleChange}
                    placeholder="Contact Number"
                  />
                  <FaPhoneAlt />
                  {isError.contact.length > 0 && (
                    <span className="error">{isError.contact}</span>
                  )}
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="business"
                    value={this.state.business}
                    onChange={this.handleChange}
                    placeholder="Business Name / URL"
                  />
                  <FaBriefcase />
                </div>
              </div>
              <div className="row">
                <div className="txtArea">
                  <textarea
                    rows="5"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                    placeholder="Your Message"
                  />
                  {this.state.submitMessage.length > 0 && (
                    <span
                      className={
                        this.state.isSubmitted ? "error success" : "error"
                      }
                    >
                      {this.state.submitMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-7">
                  <p>
                    Fill out the form with any details about your project. We
                    look forward to hearing from you!
                  </p>
                </div>
                <div className="col-5">
                  <input className="btn" type="submit" value="Submit" />
                </div>
              </div>
            </form>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#fdefc8"
              viewBox="282.14 -74.67 1248.97 1287.35"
              className="background-svg"
            >
              <path
                opacity="0.8"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1201.6 1061.22C1102.25 1120.63 1012.76 1195.43 895.457 1207.92C767.79 1221.51 630.681 1208.69 518.483 1133.95C404.055 1057.72 310.55 935.697 285.558 801.643C262.148 676.071 365.675 578.268 392.314 457.18C420.429 329.382 365.866 175.243 445.29 75.5831C530.161 -30.9105 675.545 -83.1253 815.288 -73.5604C951.734 -64.2211 1067.67 34.5596 1177.19 125.679C1278.97 210.354 1362.72 310.17 1420.06 429.581C1482.47 559.543 1562.89 706.215 1518.13 835.616C1473.74 963.948 1315.69 993.005 1201.6 1061.22Z"
                fill="#fdefc8"
              ></path>
            </svg>
          </div>
        </section>
      </Layout>
    )
  }
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date] }) {
      nodes {
        title
        excerpt
        slug
      }
    }
  }
`
//highlight-end

// form functions
const regExpEmail = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

const regExpNumber = RegExp(
  /^[\(\)\.\- ]{0,}[0-9]{3}[\(\)\.\- ]{0,}[0-9]{3}[\(\)\.\- ]{0,}[0-9]{4}[\(\)\.\- ]{0,}$/
)
